"use client";
import { apiSlice } from "@/store/api/apiSlice";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";

const initialState = {
	user: {},
	loading: false,
	error: "",
	refreshToken: "",
	accessToken: "",
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		userLoggedIn: (state, action) => {
			state.user = action.payload.user;
			state.loading = false;
			state.accessToken = action.payload.accessToken;
			state.refreshToken = action.payload.refreshToken;
			state.error = "";
		},
		userLoggedOut: (state, action) => {
			state.user = {};
			state.accessToken = "";
			state.refreshToken = "";
			state.loading = false;
			state.error = "";
		},
	},
	extraReducers(builder) {
		builder.addMatcher(
			isAnyOf(apiSlice.endpoints.loadUser.matchPending), //updated
			(state, action) => {
				state.user = {};
				state.loading = true;
				state.error = "";
				return state;
			},
		);
		builder.addMatcher(
			isAnyOf(apiSlice.endpoints.loadUser.matchFulfilled), //updated
			(state, action) => {
				state.user = action.payload.user;
				state.loading = false;
				state.error = "";
				return state;
			},
		);
		builder.addMatcher(
			isAnyOf(apiSlice.endpoints.loadUser.matchRejected), //updated
			(state, action) => {
				state.user = {};
				state.loading = false;
				state.error = action.error.message || "";
				return state;
			},
		);

		builder.addMatcher(
			isAnyOf(apiSlice.endpoints.refreshToken.matchFulfilled), //updated
			(state, action) => {
				state.accessToken = action.payload.access_token;
				state.refreshToken = action.payload.refresh_token;
			},
		);
		builder.addMatcher(
			isAnyOf(apiSlice.endpoints.refreshToken.matchRejected), //updated
			(state, action) => {
				state.user = {};
				state.loading = false;
				state.error = action.error.message || "";
				return state;
			},
		);
	},
});

export default authSlice;
